@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Asap-Regular";
  src: url(./assets/fonts/Asap-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Asap-SemiBold";
  src: url(./assets/fonts/Asap-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Asap-Medium";
  src: url(./assets/fonts/Asap-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Asap-Bold";
  src: url(./assets/fonts/Asap-Bold.ttf) format("truetype");
}
